<template>
  <ess-page-content title="Ad Management">
    Coming soon...
  </ess-page-content>
</template>

<script>
export default {
  components: {},

  props: {},

  data() {
    return {};
  },

  computed: {},

  watch: {},

  beforeCreate() {},

  created() {},

  beforeMount() {},

  mounted() {},

  beforeUpdate() {},

  updated() {},

  beforeDestroy() {},

  destroy() {},

  methods: {},
};
</script>
